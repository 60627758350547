import React from "react";

const EasyCollectPrivacyPolicyPage = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Privacy Policy - Easy Collect</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            color: #333;
        }

        h1 {
            color: #2c3e50;
            border-bottom: 2px solid #3498db;
            padding-bottom: 10px;
        }

        h2 {
            color: #2c3e50;
            margin-top: 30px;
        }

        .section {
            margin-bottom: 30px;
        }

        .last-updated {
            color: #666;
            font-style: italic;
        }

        ul {
            padding-left: 20px;
        }

        .footer {
            margin-top: 50px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            text-align: center;
            color: #666;
        }

        .logo-container {
            text-align: center;
            margin: 20px 0;
        }

        .logo {
            max-width: 200px;
            height: auto;
        }

        @media (max-width: 600px) {
            body {
                padding: 15px;
            }

            .logo {
                max-width: 150px;
            }
        }
    </style>
</head>

<body>
    // <div class="logo-container">
    //     <img src="assets/1024.png" alt="Easy Collect Logo" class="logo">
    // </div>

    <h1>Privacy Policy for Easy Collect</h1>
    <p class="last-updated">Last Updated: 15/02/2025</p>

    <div class="section">
        <h2>1. Introduction</h2>
        <p>Welcome to Easy Collect ("we," "our," or "us"). We respect your privacy and are committed to protecting any
            data that we collect. This privacy policy explains our practices regarding your information and how we
            handle it.</p>
    </div>

    <div class="section">
        <h2>2. Information Collection</h2>
        <p>Our app is designed with privacy in mind and operates primarily using local storage (AsyncStorage) on your
            device. We do not collect, store, or transmit your personal data to external servers. All your collection
            data is stored locally on your device.</p>

        <h3>2.1 Information Stored Locally:</h3>
        <ul>
            <li>Collection items data</li>
            <li>Categories and organization settings</li>
            <li>App preferences and settings</li>
            <li>User-created lists and inventories</li>
        </ul>

        <h3>2.2 Permissions Required:</h3>
        <ul>
            <li>Storage access (to save and manage your collection data locally)</li>
            <li>Camera access (if implemented, for adding photos to collections)</li>
            <li>Photo library access (if implemented, for selecting existing photos)</li>
        </ul>
    </div>

    <div class="section">
        <h2>3. Data Usage</h2>
        <h3>3.1 Local Data:</h3>
        <ul>
            <li>All data is stored locally on your device using AsyncStorage</li>
            <li>Data is only used for app functionality</li>
            <li>No data is transmitted to external servers</li>
            <li>No analytics or tracking systems are implemented</li>
        </ul>

        <h3>3.2 Data Security:</h3>
        <ul>
            <li>Your data remains on your device</li>
            <li>Data security depends on your device's security settings</li>
            <li>We recommend keeping your device's operating system up-to-date</li>
        </ul>
    </div>

    <div class="section">
        <h2>4. Data Sharing</h2>
        <p>We do not share your data with any third parties because:</p>
        <ul>
            <li>All data is stored locally on your device</li>
            <li>We do not collect or transmit any personal information</li>
            <li>We do not have access to your stored data</li>
        </ul>
    </div>

    <div class="section">
        <h2>5. Data Backup</h2>
        <h3>5.1 Local Backup:</h3>
        <ul>
            <li>Users are responsible for backing up their device data</li>
            <li>Data may be lost if the app is uninstalled or device is reset</li>
            <li>We recommend regular device backups</li>
        </ul>
    </div>

    <div class="section">
        <h2>6. Children's Privacy</h2>
        <p>Our app does not knowingly collect any personal information from children under 13. The app is intended for
            general audiences and can be safely used by children as all data remains local to the device.</p>
    </div>

    <div class="section">
        <h2>7. Changes to Privacy Policy</h2>
        <p>We may update this privacy policy from time to time. We will notify users of any changes by:</p>
        <ul>
            <li>Updating the "15/02/2025" date</li>
            <li>Providing in-app notifications of significant changes</li>
            <li>Requesting review of updated terms when necessary</li>
        </ul>
    </div>

    <div class="section">
        <h2>8. Your Rights</h2>
        <p>You have complete control over your data because:</p>
        <ul>
            <li>All data is stored locally on your device</li>
            <li>You can delete app data at any time</li>
            <li>You can uninstall the app to remove all associated data</li>
        </ul>
    </div>

    <div class="section">
        <h2>9. Contact Information</h2>
        <p>If you have questions about this privacy policy or our privacy practices, please contact us at:<br>
            [Your Contact Email]</p>
    </div>

    <div class="section">
        <h2>10. Consent</h2>
        <p>By using our app, you consent to our privacy policy and agree to its terms. If you disagree with this policy,
            please do not use our app.</p>
    </div>

    <div class="section">
        <h2>11. App Stores</h2>
        <p>This app is distributed through Google Play Store and may be subject to additional privacy requirements as
            per their policies.</p>
    </div>

    <div class="section">
        <h2>12. Data Deletion</h2>
        <p>To delete all app data, you can:</p>
        <ul>
            <li>Clear app data through your device settings</li>
            <li>Uninstall the app</li>
            <li>Use the in-app reset feature (if implemented)</li>
        </ul>
    </div>

    <div class="footer">
        <p>Easy Collect App<br>
            Last Updated: 15/02/2025</p>
    </div>
</body>

</html>`,
      }}
    />
  );
};
export default EasyCollectPrivacyPolicyPage;
