import React from "react";
import { FaMoneyBillWave, FaStore, FaWifi } from "react-icons/fa";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header className="header">
        <h1>Easy Collect</h1>
        <p>Simplify your collection and payment tracking</p>
        <a
          href="https://play.google.com/store/apps/details?id=com.okbill.easycollect"
          className="download-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download on Play Store
        </a>
      </header>

      <section className="features">
        <h2>Why Choose Easy Collect?</h2>
        <div className="feature-list">
          <div className="feature">
            <FaMoneyBillWave className="feature-icon" />
            <h3>Track Collections</h3>
            <p>Easily manage your collection trips and payments.</p>
          </div>
          <div className="feature">
            <FaStore className="feature-icon" />
            <h3>Shop Management</h3>
            <p>Keep records of shops, payments, and visits.</p>
          </div>
          <div className="feature">
            <FaWifi className="feature-icon" />
            <h3>Offline Support</h3>
            <p>Use the app without an internet connection.</p>
          </div>
        </div>
      </section>

      <section className="cta">
        <h2>Get Started Today!</h2>
        <p>Download Easy Collect and take control of your payments.</p>
        <a
          href="https://play.google.com/store/apps/details?id=com.okbill.easycollect"
          className="download-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download on Play Store
        </a>
      </section>

      <footer className="footer">
        <p>&copy; 2025 Easy Collect. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
